#root,
body,
html {
  height: 100%;
}
a,
blockquote,
body,
code,
dd,
div,
dl,
dt,
em,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
iframe,
img,
input,
label,
li,
object,
ol,
p,
q,
small,
span,
strong,
table,
tbody,
td,
th,
tr,
ul {
  border: 0;
  margin: 0;
  padding: 0;
}

body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: none;
}

a,
a:active,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
}

a,
img {
  -webkit-touch-callout: none;
}

li,
ol,
ul {
  list-style: none;
}

input[type="password"],
input[type="text"],
textarea {
  word-wrap: break-word;
  background: #fff;
  outline: 0;
  resize: none;
  white-space: pre-wrap;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}

html {
  font-size: 13.33333vw;
}

@media screen and (max-width: 320px) {
  html {
    font-size: 42.667px;
    font-size: 13.33333vw;
  }
}

@media screen and (min-width: 321px) and (max-width: 360px) {
  html {
    font-size: 48px;
    font-size: 13.33333vw;
  }
}

@media screen and (min-width: 361px) and (max-width: 375px) {
  html {
    font-size: 50px;
    font-size: 13.33333vw;
  }
}

@media screen and (min-width: 376px) and (max-width: 393px) {
  html {
    font-size: 52.4px;
    font-size: 13.33333vw;
  }
}

@media screen and (min-width: 394px) and (max-width: 412px) {
  html {
    font-size: 54.93px;
    font-size: 13.33333vw;
  }
}

@media screen and (min-width: 413px) and (max-width: 414px) {
  html {
    font-size: 55.2px;
    font-size: 13.33333vw;
  }
}

@media screen and (min-width: 415px) and (max-width: 480px) {
  html {
    font-size: 64px;
    font-size: 13.33333vw;
  }
}

@media screen and (min-width: 481px) and (max-width: 540px) {
  html {
    font-size: 72px;
    font-size: 13.33333vw;
  }
}

@media screen and (min-width: 541px) and (max-width: 640px) {
  html {
    font-size: 85.33px;
    font-size: 13.33333vw;
  }
}

@media screen and (min-width: 641px) and (max-width: 720px) {
  html {
    font-size: 96px;
    font-size: 13.33333vw;
  }
}

@media screen and (min-width: 721px) and (max-width: 768px) {
  html {
    font-size: 102.4px;
    font-size: 13.33333vw;
  }
}

@media screen and (min-width: 769px) {
  html {
    font-size: 102.4px;
    font-size: 13.33333vw;
  }
}

